var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      class:
        _vm.params &&
        _vm.params.context &&
        _vm.params.context.componentParent.tilemode === "list"
          ? ""
          : "container-lrg",
    },
    [
      _vm.icon != null
        ? [
            !_vm.previewUrl
              ? _c("font-awesome-icon", {
                  class:
                    _vm.params &&
                    _vm.params.context &&
                    _vm.params.context.componentParent.tilemode === "list"
                      ? "file-label-icon"
                      : "file-label-icon-lrg",
                  attrs: { icon: _vm.icon, "fixed-width": "" },
                })
              : _c("img", {
                  class:
                    _vm.params &&
                    _vm.params.context &&
                    _vm.params.context.componentParent.tilemode === "list"
                      ? "thumbnail-sml"
                      : "thumbnail-lrg",
                  attrs: { src: _vm.previewUrl },
                  on: {
                    error: function ($event) {
                      _vm.previewUrl = null
                    },
                  },
                }),
          ]
        : _vm._e(),
      _c("span", { staticClass: "file-label-text" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }