var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("font-awesome-icon", {
        staticClass: "folder-label-icon",
        attrs: { icon: ["far", "folder"] },
      }),
      _c("span", { staticClass: "folder-label-text" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }