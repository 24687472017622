var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tag-list",
      staticStyle: { width: "100%" },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        { staticClass: "tag-label" },
        [
          _c("label", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.$t("tag.field.list", [_vm.tagData.length]))),
          ]),
          _c(
            "b-alert",
            {
              staticClass: "mb-0 alert-box",
              attrs: {
                dismissible: "",
                fade: "",
                show: _vm.showError,
                variant: "success",
              },
              on: { dismissed: _vm.dismissAlert },
              scopedSlots: _vm._u([
                {
                  key: "dismiss",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "check"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["far", "check"] } }),
              _vm._v("   " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.canAdd() && !_vm.editor && !_vm.readOnly
        ? _c(
            "button",
            {
              staticClass: "btn-action",
              attrs: { id: "TAG_ADD_" + _vm.id },
              on: {
                click: function ($event) {
                  _vm.tagShow = true
                },
              },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } }),
              _c("b-popover", {
                attrs: {
                  target: "TAG_ADD_" + _vm.id,
                  placement: "top",
                  triggers: "hover",
                  content: _vm.$t("button.tag_add"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.canAdd() && !_vm.editor && !_vm.readOnly && _vm.enableEntityOptions
        ? [
            _c(
              "b-dropdown",
              {
                ref: "entity_list",
                staticClass: "action-bar-dropdown",
                attrs: {
                  id: "TAG_ADD_ENTITY_" + _vm.id,
                  "toggle-class": "text-decoration-none btn-action pt-0 pb-0",
                  "no-caret": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "square-chevron-down"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  763014085
                ),
              },
              [
                _c(
                  "b-dropdown-group",
                  { attrs: { header: _vm.$t("label_entities") } },
                  [
                    _vm._l(_vm.entityOptions, function (opt, index) {
                      return [
                        _c(
                          "b-dropdown-item",
                          {
                            key: index,
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.onListEntityClick(opt.value)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "action-item-label" }, [
                              _vm._v(_vm._s(opt.text)),
                            ]),
                            _vm.tagData.find(function (i) {
                              return i.name == opt.value
                            }) != null
                              ? _c("font-awesome-icon", {
                                  staticClass: "active-check",
                                  attrs: { icon: ["far", "check"] },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("b-popover", {
              attrs: {
                target: "TAG_ADD_ENTITY_" + _vm.id,
                placement: "top",
                triggers: "hover",
                content: _vm.$t("button.add_entity_tag"),
              },
            }),
          ]
        : _vm._e(),
      _c("BadgeGroup", {
        attrs: { readOnly: _vm.readOnly },
        on: { click: _vm.addTag },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("Badge", {
                  key: "" + item.edit + index,
                  attrs: {
                    text: item.name,
                    edit: item.edit,
                    variant: "primary",
                    listvalues: _vm.listvalues,
                    pillable: !!item.pillable,
                    readOnly: _vm.readOnly,
                  },
                  on: {
                    badgeRemove: function ($event) {
                      return _vm.badgeRemove(index)
                    },
                    badgeClick: function ($event) {
                      return _vm.badgeClick(index)
                    },
                    commit: function ($event) {
                      var i = arguments.length,
                        argsArray = Array(i)
                      while (i--) argsArray[i] = arguments[i]
                      return _vm.commitEdit.apply(
                        void 0,
                        [item, index].concat(argsArray)
                      )
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tagData,
          callback: function ($$v) {
            _vm.tagData = $$v
          },
          expression: "tagData",
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("tag.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.selected.length > 1
                      ? "tag.confirmation.delete_plural"
                      : "tag.confirmation.delete"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _vm.tagShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.tagShow,
              entityService: _vm.tagUtil,
              entity: "TAG",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.tagShow = $event
              },
              ok: _vm.tagSuccess,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }