var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { shown: _vm.initModal, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _vm.allowSelect
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.disableOk,
                              size: "sm",
                              variant: "success",
                            },
                            on: { click: _vm.ok },
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$i18n.t(
                            "MANAGE" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _vm.alertMsg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertMsg,
                  details: _vm.alertMsgDetails.list,
                  detailTitle: _vm.alertMsgDetails.title,
                  alertState: _vm.alertState,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _c(
            "b-input-group",
            { staticClass: "mb-1 no-focus" },
            [
              _c(
                "b-input-group-prepend",
                { staticClass: "border" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "folder-path-icon",
                    attrs: { icon: ["far", "folder"] },
                  }),
                ],
                1
              ),
              _c("b-form-input", {
                staticClass: "rounded-0 form-input-readonly border",
                attrs: { type: "text", id: "pathname", readonly: "" },
                model: {
                  value: _vm.currentPathName,
                  callback: function ($$v) {
                    _vm.currentPathName = $$v
                  },
                  expression: "currentPathName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "action-container grid-toolbar" },
            [
              _c(
                "b-button-toolbar",
                {
                  staticClass: "py-1 action-toolbar w-50",
                  attrs: { justify: "" },
                },
                [
                  _c(
                    "b-button-group",
                    { staticClass: "pl-1 action" },
                    [
                      _vm.allowManage
                        ? [
                            _vm.canAdd()
                              ? [
                                  _c(
                                    "span",
                                    {
                                      attrs: { id: `FOLDER_BTN_ADD_${_vm.id}` },
                                    },
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.folderOpen(true)
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["far", "folder-plus"],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: `FOLDER_BTN_ADD_${_vm.id}`,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("folder.title_new")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm.canView()
                              ? [
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        id: `FOLDER_BTN_EDIT_${_vm.id}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          attrs: {
                                            disabled: _vm.disableFolderEdit,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.folderOpen(false)
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["far", "pen-to-square"],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: `FOLDER_BTN_EDIT_${_vm.id}`,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("folder.title_detail")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm.canDelete()
                              ? [
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        id: `FOLDER_BTN_DELETE_${_vm.id}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          attrs: {
                                            disabled: _vm.disableDeleteFolder,
                                          },
                                          on: { click: _vm.deleteFolder },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["far", "trash-can"],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: `FOLDER_BTN_DELETE_${_vm.id}`,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("folder.delete_folder")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-button-toolbar",
                {
                  staticClass: "py-1 action-toolbar w-50",
                  attrs: { justify: "" },
                },
                [
                  _c(
                    "b-button-group",
                    { staticClass: "pl-1 action" },
                    [
                      _vm.allowManage && _vm.canAdd()
                        ? [
                            _c(
                              "span",
                              { attrs: { id: `FILE_BTN_UPLOAD_${_vm.id}` } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.disableUpload },
                                    on: { click: _vm.promptUpload },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "upload"] },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `FILE_BTN_UPLOAD_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("file.upload_file")) + " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.canView()
                        ? [
                            _c(
                              "span",
                              { attrs: { id: `FILE_BTN_EDIT_${_vm.id}` } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.disableDetails },
                                    on: { click: _vm.fileDetails },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon:
                                          "SELECT" === _vm.mode
                                            ? ["far", "rectangle-list"]
                                            : ["far", "pen-to-square"],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `FILE_BTN_EDIT_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("file.title.details")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.allowManage && _vm.canEdit()
                        ? [
                            _c(
                              "span",
                              { attrs: { id: `FILE_BTN_MOVE_${_vm.id}` } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.disableDownload },
                                    on: { click: _vm.fileMove },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "up-right-from-square"],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `FILE_BTN_MOVE_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("file.move_file")) + " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.canView()
                        ? [
                            _c(
                              "span",
                              { attrs: { id: `FILE_BTN_DOWNLOAD_${_vm.id}` } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.disableDownload },
                                    on: { click: _vm.fileDownload },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "download"] },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `FILE_BTN_DOWNLOAD_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("file.download_file")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.allowManage && _vm.canDelete()
                        ? [
                            _c(
                              "span",
                              { attrs: { id: `FILE_BTN_DELETE_${_vm.id}` } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.disableDelete },
                                    on: { click: _vm.fileDelete },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "trash-can"] },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `FILE_BTN_DELETE_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("file.delete_file")) + " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.allowManage
                        ? [
                            _c(
                              "b-btn",
                              {
                                staticClass: "preview-button",
                                attrs: { id: `FILE_BTN_LIST_${_vm.id}` },
                                on: { click: _vm.toggleTileMode },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: [
                                      "far",
                                      _vm.tilemode === "list"
                                        ? "th-list"
                                        : "th-large",
                                    ],
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `FILE_BTN_LIST_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tilemode === "list"
                                        ? _vm.$t("file.list")
                                        : _vm.$t("file.thumbnail")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm.allowManage
                        ? [
                            _c(
                              "b-btn",
                              {
                                staticClass: "preview-button",
                                attrs: {
                                  id: `FILE_BTN_PREVIEW_${_vm.id}`,
                                  pressed: _vm.showPreview,
                                },
                                on: {
                                  "update:pressed": function ($event) {
                                    _vm.showPreview = $event
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "image"] },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: `FILE_BTN_PREVIEW_${_vm.id}`,
                                  triggers: "hover",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("file.showPreview")) + " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                            _vm.colorMouseEnterEvent,
                            _vm.onColoringOver,
                          ]),
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              ref: "coloring",
                              staticClass: "color-dropdown action-bar-dropdown",
                              attrs: {
                                id: `BTN_COLORING_${_vm.id}`,
                                "toggle-class": "text-decoration-none",
                                "no-caret": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "palette"] },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-dropdown-group",
                                { attrs: { header: _vm.$t("colorby") } },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onColorChange("none")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [_vm._v(_vm._s(_vm.$t("none")))]
                                      ),
                                      _vm.coloring.none
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onColorChange("file")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "action-item-label" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("file.coloring.file"))
                                          ),
                                        ]
                                      ),
                                      _vm.coloring.file
                                        ? _c("font-awesome-icon", {
                                            staticClass: "active-check",
                                            attrs: { icon: ["far", "check"] },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "pane-container" }, [
            _c(
              "div",
              { staticClass: "folder-pane" },
              [
                _c("ag-grid-vue", {
                  staticClass: "ag-theme-balham file-selector-grid-height",
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "folderGrid",
                    gridOptions: _vm.folderGridOptions,
                    autoGroupColumnDef: _vm.autoFolderGroupColumnDef,
                    columnDefs: [],
                    overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                    defaultColDef: _vm.defaultColDef,
                    getRowId: (params) => {
                      return params.data.uuId
                    },
                    rowData: _vm.rowData,
                    rowModelType: "serverSide",
                    serverSideInfiniteScroll: true,
                    suppressContextMenu: "",
                    suppressMultiSort: "",
                    suppressRowClickSelection: "",
                    treeData: "",
                  },
                  on: { "grid-ready": _vm.onFolderGridReady },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "files-pane" },
              [
                _c("ag-grid-vue", {
                  staticClass: "ag-theme-balham file-selector-grid-height",
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "fileGrid",
                    gridOptions: _vm.fileGridOptions,
                    columnDefs: _vm.fileColumnDefs,
                    defaultColDef: _vm.fileDefaultColDef,
                    context: _vm.context,
                    overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                    getRowId: (params) => {
                      return params.data.uuId
                    },
                    rowData: _vm.rowData,
                    rowMultiSelectWithClick: "",
                    rowModelType: "serverSide",
                    rowSelection: _vm.multiple ? "multiple" : "single",
                    suppressContextMenu: "",
                    suppressMultiSort: "",
                  },
                  on: { "grid-ready": _vm.onFileGridReady },
                }),
                _vm.previewUrl !== null
                  ? _c("div", { staticClass: "file-preview" }, [
                      _c("img", {
                        attrs: { src: _vm.previewUrl },
                        on: { error: _vm.previewImageError },
                      }),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Confirm Deletion",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.strFormat(
                    _vm.$i18n.t("file.confirmation.delete_file"),
                    _vm.selected.length > 1 ? "s" : ""
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Confirm Deletion",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteFolderOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteFolderShow,
            callback: function ($$v) {
              _vm.confirmDeleteFolderShow = $$v
            },
            expression: "confirmDeleteFolderShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$i18n.t(
                    _vm.hasChildNodes()
                      ? "file.confirmation.delete_folder_and_child_files"
                      : "file.confirmation.delete_folder",
                    [
                      _vm.selectedFolder.length === 1
                        ? _vm.selectedFolder[0].name
                        : "",
                    ]
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("FolderModal", {
        attrs: {
          currentPath: _vm.currentPath,
          id: _vm.folderId,
          name: _vm.selectedFolderName,
          parentData: _vm.selectedParent,
          show: _vm.folderShow,
          title: _vm.folderTitle,
          readOnly: !_vm.canEdit(),
        },
        on: {
          "update:show": function ($event) {
            _vm.folderShow = $event
          },
          success: _vm.folderSuccess,
        },
      }),
      _c("FileDetailsModal", {
        attrs: {
          id: _vm.detailsId,
          currentPath: _vm.currentPath,
          parentData: _vm.selectedParent,
          show: _vm.detailsShow,
          mode: _vm.mode,
        },
        on: {
          "update:show": function ($event) {
            _vm.detailsShow = $event
          },
          success: _vm.fileDetailsSuccess,
        },
      }),
      _c("FolderSelectorModal", {
        attrs: { currentPath: _vm.currentPath, show: _vm.promptFolder },
        on: {
          "update:show": function ($event) {
            _vm.promptFolder = $event
          },
          success: _vm.folderSelectedSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Download",
            centered: "",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            "hide-footer": "",
          },
          model: {
            value: _vm.downloadProgressShow,
            callback: function ($$v) {
              _vm.downloadProgressShow = $$v
            },
            expression: "downloadProgressShow",
          },
        },
        [
          _c(
            "b-progress",
            { attrs: { max: 100, height: "2rem" } },
            [
              _c("b-progress-bar", {
                attrs: {
                  value: _vm.downloadPercentage,
                  label: `${_vm.downloadPercentage.toFixed(0)}%`,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "visible mt-2",
                  attrs: { variant: "secondary" },
                  on: { click: _vm.downloadCancel },
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("button.cancel")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }