<template>
  <div :id="id" class="tag-list" style="width: 100%">
    <div class="tag-label">
      <label class="mr-1">{{ $t(`tag.field.list`, [tagData.length]) }}</label>
      <b-alert dismissible fade :show="showError" variant="success" @dismissed="dismissAlert" class="mb-0 alert-box">
        <font-awesome-icon :icon="['far', 'check']"/>
        &nbsp;&nbsp;{{ alertMsg }}
        <template v-slot:dismiss="">
          <font-awesome-icon :icon="['far','check']"/>
        </template>
      </b-alert> 
    </div>
    
    <button v-if="canAdd() && !editor && !readOnly" :id="`TAG_ADD_${id}`" class="btn-action" @click="tagShow = true"><font-awesome-icon :icon="['far', 'plus']"/>
      <b-popover
        :target="`TAG_ADD_${id}`"
        placement="top"
        triggers="hover"
        :content="$t('button.tag_add')">
      </b-popover>
    </button>

    <template v-if="canAdd() && !editor && !readOnly && enableEntityOptions">
      <b-dropdown :id="`TAG_ADD_ENTITY_${id}`" ref="entity_list" 
        class="action-bar-dropdown" toggle-class="text-decoration-none btn-action pt-0 pb-0" no-caret>
        <template #button-content>
          <font-awesome-icon :icon="['far', 'square-chevron-down']"/>
        </template>
        <b-dropdown-group :header="$t('label_entities')">
          <template v-for="(opt, index) in entityOptions">
            <b-dropdown-item @click="onListEntityClick(opt.value)" href="#" :key="index">
              <span class="action-item-label">{{ opt.text }}</span><font-awesome-icon class="active-check" v-if="tagData.find(i => i.name == opt.value) != null" :icon="['far', 'check']"/>
            </b-dropdown-item>
          </template>
        </b-dropdown-group>      
      </b-dropdown>
      <b-popover
        :target="`TAG_ADD_ENTITY_${id}`"
        placement="top"
        triggers="hover"
        :content="$t('button.add_entity_tag')">
      </b-popover>
    </template>

   <BadgeGroup v-model="tagData" @click="addTag" :readOnly="readOnly">
      <template v-slot:default="{ item, index }">
        <Badge @badgeRemove="badgeRemove(index)"
          @badgeClick="badgeClick(index)"
          @commit="commitEdit(item, index, ...arguments)"
          :text="item.name" 
          :edit="item.edit"
          variant="primary" 
          :listvalues="listvalues"
          :pillable="!!item.pillable" :key="`${item.edit}${index}`"
          :readOnly="readOnly" />
        </template>
    </BadgeGroup>

    <b-modal :title="$t('tag.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length > 1? 'tag.confirmation.delete_plural':'tag.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- tag selector -->
    <GenericSelectorModalForAdmin v-if="tagShow"
      :show.sync="tagShow" 
      :entityService="tagUtil" 
      entity="TAG"
      nonAdmin
      @ok="tagSuccess"
    />
  </div>
</template>

<script>
import { strRandom } from '@/helpers';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import { taskService, tagService } from '@/services';
import { tagUtil } from '@/views/management/script/tag';
export default {
  name: 'TagList',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin'),
    'detailLinkCellRenderer': DetailLinkCellRenderer // eslint-disable-line vue/no-unused-components
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: 'Tag Selector'
    },
    tags: {
      type: Array,
      default: () => []
    },
    holderId: {
      type: String,
      required: false
    },
    editor: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    enableEntityOptions: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      permissionName: 'TAG',
      id: `TAG_LIST_${strRandom(5)}`,
      tagData: [],
      
      disableEdit: true,
      disableDelete: true,
      disableOk: true,
      selected: [],

      tagId: null,
      tagShow: false,
      alertMsg: null,

      confirmDeleteShow: false,
      totalRecords: 0,
      listvalues: [],
      entityOptions: []
    };
  },
  created() {
    this.tagUtil = tagUtil;
    this.tagData = JSON.parse(JSON.stringify(this.tags));
    this.getListValues();
    if (this.enableEntityOptions) {
      this.getEntityOptions();
    }
  },
  beforeDestroy() {
    this.tagUtil = null;
  },
  watch: {
    tags: function(newValue) {
      this.tagData = JSON.parse(JSON.stringify(newValue));
    }
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    tagTitle() {
      return this.tagId && this.tagId.indexOf('TAG_NEW') == -1? this.$t('tag.title_detail'): this.$t('tag.title_selector');
    }
  },
  methods: {
    getListValues() {
      const self = this;
      tagService.list({ start: 0, limit: -1}).then((response) => {
        const list = response.data.map(d => {return d.name});
        list.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        self.$set(self, 'listvalues', list);
      });
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    badgeRemove(index) {
      this.tagData.splice(index, 1);
      this.$emit('modified', {tags: this.tagData});
    },
    badgeClick(index) {
      this.$set(this.tagData[index], 'edit', true);
    },
    tagSuccess({details}) {
      let have = {};
      this.tagData.map(x => have[x.uuId] = true);

      // Append any new stages to the existing list
      details.forEach(item => {
        if (!(item.uuId in have)) {
          this.tagData.push(item);
        }
      })
      this.$emit('modified', {tags: this.tagData});
    },
    commitEdit(item, index, value) {
      const existIdx = this.tagData.findIndex(t => t.name === value);
      if (value &&
          // does not exist or exists and this is the same item
          (existIdx === -1 || existIdx === index)) {
        item.edit = false;
        if (item.name !== value) {
          delete item.uuId;
        }
        item.name = value;
      }
      else {
        this.tagData.splice(index, 1);
      }
      this.$emit('modified', {tags: this.tagData});
    },
    tagAdd() {
      this.tagId = null;
      this.tagShow = true;
      this.alertMsg = null;
    },
    async tagDelete() {
      // If any of the tags we want to delete has tasks assigned to it, show
      // a warning.
      let tasks = await taskService.listAssignedTag(this.holderId, this.selected)
      .then(response => {
        return response.data || null;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });

      if (tasks == null) {
        // Something went wrong with the api, don't proceed
        console.log("Task tag check error"); // eslint-disable-line no-console
      } else if (tasks.length) {
        this.confirmDeleteShow = true;
      } else {
        // Otherwise just let it through
        this.confirmDeleteOk();
      }
    },
    confirmDeleteOk() {
      this.$emit('modified', {tags: this.tagData});
    },
    addTag() {
      if (!this.canAdd() || this.readOnly) {
        return;
      }

      for (let i = 0; i < this.tagData.length; i++) {
        if (this.tagData[i].edit) {
          this.$set(this.tagData[i], 'edit', false); // clear the edit flag so we only edit 1 at a time
        }
      }
      this.tagData.push({ name: '', edit: true });
      
      // for (let i = 0; i < this.tags.length; i++) {
      //   if (this.tags[i].edit) {
      //     this.$set(this.tags[i], 'edit', false); // clear the edit flag so we only edit 1 at a time
      //   }
      // }
      // this.tags.push({ name: '', edit: true });
    },
    getEntityOptions() {
      this.$store.dispatch('data/info', {type: "holder", object: "STAGE,TAG"}).then(value => {
        this.entityOptions.splice(0, this.entityOptions.length);
        for(const v of value) {
          this.entityOptions.push({ value: v, text: v });
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    onListEntityClick(value) {
      const index = this.tagData.findIndex(i => i.name == value);
      if (index > -1) {
        this.tagData.splice(index, 1);
      } else {
        this.tagData.push({ name: value })
      }
      this.$emit('modified', {tags: this.tagData});
    }
  }
}


</script>

<style lang="scss">
  .tag-label {
    display: inline-block;
  }
  
  .tag-list {
    .tag-toolbar {
      .btn.btn-secondary {
        background-color: transparent;
        border-color: transparent;
        padding: 2px 6px;
        margin: 8px 3px;
        border-radius: 3.2px;
        color: var(--grid-toolbar-button);

        &:focus {
          box-shadow: none;
        }
      }
      span:first-child {
        margin-left: 8px;
      }
    }

    .alert-box {
      padding: 6px 12px;
      .close {
        padding: 7px 12px;
        font-size: 21px;
      }
    } 
  }

  .tag-grid-height {
    height: 150px;
    min-height: 150px;
  }
</style>